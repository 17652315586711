import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'StartPage', component: () => import(/* webpackChunkName: "StartPage" */ '../components/StartPage.vue'), meta : { forAuth: true }  },
  { path: '/users', name: 'Users', component: () => import(/* webpackChunkName: "Users" */ '../components/Users.vue'), meta : { forAuth: true }  },
  { path: '/rumcs', name: 'Rumcs', component: () => import(/* webpackChunkName: "Rumcs" */ '../components/Rumcs.vue'), meta : { forAuth: true }  },
  { path: '/events', name: 'Events', component: () => import(/* webpackChunkName: "Events" */ '../components/Events.vue'), meta : { forAuth: true }  },
  { path: '/organizations', name: 'Organizations', component: () => import(/* webpackChunkName: "Organizations" */ '../components/Organizations.vue'), meta : { forAuth: true }  },
  { path: '/news', name: 'News', component: () => import(/* webpackChunkName: "Organizations" */ '../components/News.vue'), meta : { forAuth: true }  },
  { path: '/forbidden', name: 'Forbidden', component: () => import(/* webpackChunkName: "Organizations" */ '../components/Forbidden.vue') },

]

const router = new VueRouter({
  mode : 'history',
  routes
})

export default router
