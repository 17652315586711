<template>
  <svg class="ru" viewBox="0 0 350 110" height="50">
    <g>
        <path d="M28.46,57a53,53,0,1,0,53-53,53,53,0,0,0-53,53" style="fill: rgb(252, 218, 45);"></path>
        <polygon points="107.55 34.72 49.77 2.79 53.79 36.1 96.57 45.48 96.8 72.47 102.44 76.36 106.03 64.08 107.55 34.72" style="fill: rgb(187, 189, 188);"></polygon>
        <polygon points="0 29.16 50.59 74.99 56.76 87.34 96.8 72.47 96.57 45.48 0 29.16" style="fill: rgb(187, 189, 188);"></polygon>
        <polygon points="138.98 65.6 118.57 55.78 116.02 66.52 138.98 65.6" style="fill: rgb(187, 189, 188);"></polygon>
        <polygon points="119.45 77.73 115.39 80.48 115.83 66.53 118.2 66.99 119.45 77.73" style="fill: rgb(187, 189, 188);"></polygon>
        <polygon points="106.03 64.08 116.02 57.37 115.25 84.75 102.44 76.36 106.03 64.08" style="fill: rgb(187, 189, 188);"></polygon>
        <polygon points="56.76 87.34 96.8 92.95 115.25 84.75 96.8 72.47 56.76 87.34" style="fill: rgb(187, 189, 188);"></polygon>
        <polygon points="20.98 81.64 56.76 87.34 96.8 92.95 74.61 95.92 20.98 81.64" style="fill: rgb(187, 189, 188);"></polygon>
        <polygon points="114.47 30.2 60.71 0.92 60.71 31.57 103.49 40.95 103.72 67.95 109.36 71.84 112.94 59.56 114.47 30.2" style="fill: rgb(255, 255, 255);"></polygon>
        <path d="M109.36,72.75a.89.89,0,0,1-.52-.16L103.2,68.7a.9.9,0,0,1-.4-.74l-.22-26.27L60.51,32.47a.93.93,0,0,1-.72-.9V.91a.92.92,0,0,1,1.35-.8L114.91,29.4a.9.9,0,0,1,.47.85l-1.52,29.36a1.55,1.55,0,0,1,0,.21l-3.58,12.27a.92.92,0,0,1-.57.61.84.84,0,0,1-.31,0m-4.73-5.29,4.21,2.9,3.2-11,1.49-28.68L61.62,2.46V30.83l42.06,9.23a.91.91,0,0,1,.72.88Z" style="fill: rgb(19, 24, 25);"></path>
        <polygon points="14.72 22.88 57.5 70.47 63.68 82.82 103.72 67.95 103.49 40.95 14.72 22.88" style="fill: rgb(255, 255, 255);"></polygon>
        <path d="M63.68,83.73a.91.91,0,0,1-.82-.5L56.74,71,14,23.49a.92.92,0,0,1-.11-1.07.91.91,0,0,1,1-.44l88.77,18.07a.92.92,0,0,1,.73.89l.23,27a.91.91,0,0,1-.59.87L64,83.68a.89.89,0,0,1-.32,0M17.25,24.33,58.18,69.85a.9.9,0,0,1,.14.2l5.81,11.62L102.8,67.31l-.22-25.61Z" style="fill: rgb(19, 24, 25);"></path>
        <polygon points="139.94 65.05 122.93 52.85 122.93 62 139.94 65.05" style="fill: rgb(255, 255, 255);"></polygon>
        <path d="M139.94,66l-.16,0-17-3.05A.92.92,0,0,1,122,62V52.85a.91.91,0,0,1,1.45-.74l17,12.2a.92.92,0,0,1-.53,1.66m-16.09-4.74,12.27,2.2-12.27-8.8Z" style="fill: rgb(19, 24, 25);"></path>
        <polygon points="126.36 73.21 122.3 75.95 122.74 62 125.12 62.46 126.36 73.21" style="fill: rgb(255, 255, 255);"></polygon>
        <path d="M122.31,76.87a1,1,0,0,1-.45-.11.92.92,0,0,1-.47-.83l.44-14a.89.89,0,0,1,.34-.69,1,1,0,0,1,.75-.18l2.37.46a.92.92,0,0,1,.74.8l1.24,10.74a.92.92,0,0,1-.39.87l-4.06,2.74a.88.88,0,0,1-.51.16m1.31-13.77-.34,11.09,2.11-1.43-1.1-9.53Z" style="fill: rgb(19, 24, 25);"></path>
        <polygon points="112.94 59.56 122.94 52.85 122.17 80.23 109.36 71.84 112.94 59.56" style="fill: rgb(255, 255, 255);"></polygon>
        <path d="M122.17,81.14a.91.91,0,0,1-.5-.15L108.86,72.6a.91.91,0,0,1-.38-1l3.59-12.28a1,1,0,0,1,.36-.5l10-6.71a.9.9,0,0,1,1,0,.89.89,0,0,1,.48.82l-.76,27.38a.9.9,0,0,1-.92.89m-11.74-9.7,10.87,7.12.67-24-8.24,5.53Z" style="fill: rgb(19, 24, 25);"></path>
        <polygon points="63.68 82.82 103.72 88.42 122.17 80.22 103.72 67.95 63.68 82.82" style="fill: rgb(255, 255, 255);"></polygon>
        <path d="M103.72,89.34h-.13l-40-5.6a.93.93,0,0,1-.78-.81.92.92,0,0,1,.59-1l40-14.87a.89.89,0,0,1,.82.1l18.46,12.27a.91.91,0,0,1,.4.84.88.88,0,0,1-.54.76l-18.45,8.2a1,1,0,0,1-.37.08M67.4,82.41l36.18,5.07,16.7-7.41L103.6,69Z" style="fill: rgb(19, 24, 25);"></path>
        <polygon points="12.2 80.23 63.68 82.82 103.72 88.42 81.53 91.4 12.2 80.23" style="fill: rgb(255, 255, 255);"></polygon>
        <path d="M81.53,92.31h-.15L12.06,81.13a.92.92,0,0,1-.77-1,.94.94,0,0,1,1-.82L63.73,81.9h.08l40,5.61a.91.91,0,0,1,0,1.81l-22.19,3h-.12M28.84,82l52.7,8.49L97,88.4,63.59,83.73Z" style="fill: rgb(19, 24, 25);"></path>
    </g>
    <g class="ru">
        <path d="M150.56,55V34.71h-2.42V32.18h7.2V47c.35-.87.8-1.8,1.22-2.64l6.06-12.16h4.06V50.82c0,.63-.11,1.56.83,1.56a3.66,3.66,0,0,0,1.25-.45l.86,1.81a6.6,6.6,0,0,1-4.26,1.42,3.68,3.68,0,0,1-2.7-1c-.8-.9-.76-2.18-.76-3.36V40.7c-.25.56-.77,1.67-1,2.15L154.76,55Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M185,39.49V50.82c0,.63-.1,1.56.83,1.56a4,4,0,0,0,1.29-.45l.83,1.81a5.52,5.52,0,0,1-3.88,1.42,3.71,3.71,0,0,1-2.71-1,4.91,4.91,0,0,1-.76-3.36V48.64h-4.37V55h-4.36V41.78h-2.26V39.49h6.62v6.45h4.37V39.49Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M202.41,39.49a18.56,18.56,0,0,1-.35,3A7.26,7.26,0,0,1,201,45a5.38,5.38,0,0,1-2.18,1.76,3.87,3.87,0,0,1,2.53,2.33c.52,1.07.72,1.8,1.14,2.63.14.35.31.69.73.69a4,4,0,0,0,1.28-.45l.83,1.81a5.49,5.49,0,0,1-3.84,1.42A3,3,0,0,1,199,53.94a19.5,19.5,0,0,1-1.18-2.63,5.59,5.59,0,0,0-1.22-2.11,2.41,2.41,0,0,0-1.76-.66h-.38V55H190V41.78h-2.25V39.49h6.62V45.8h.17a3,3,0,0,0,2.08-.69A6,6,0,0,0,197.91,42a22.59,22.59,0,0,0,.17-2.46Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M206,50.1c.21.17.48.48.76.72a1.72,1.72,0,0,0,1,.49c.59,0,.8-.52.83-.94l.7-8.56H207V39.49h8.91c1,0,2.08,0,2.77.8.56.66.52,1.7.52,2.91v7.62c0,.63-.1,1.56.87,1.56a4.16,4.16,0,0,0,1.25-.45l.83,1.81a5.52,5.52,0,0,1-3.88,1.42,3.77,3.77,0,0,1-2.71-1,4.91,4.91,0,0,1-.76-3.36v-8.6h-2.53l-.66,8.29c-.14,1.38-.17,2.7-1.28,3.74a3.49,3.49,0,0,1-2.63.94,4.67,4.67,0,0,1-2.33-.73,5.1,5.1,0,0,1-1.56-1.73Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M224,55V41.78h-2.25V39.49h6.62V46h2a8.4,8.4,0,0,1,2.11-4.85,6.24,6.24,0,0,1,4.75-1.87,6.48,6.48,0,0,1,5,2.08,8.51,8.51,0,0,1,2.08,6.1,8.48,8.48,0,0,1-1.9,5.4,6.56,6.56,0,0,1-5.3,2.36,6.34,6.34,0,0,1-5-2,8.09,8.09,0,0,1-1.76-4.5h-2V55Zm10.51-7.76c0,1.73.24,5.4,2.63,5.44,2.6,0,2.74-3.61,2.77-5.38s-.27-5.37-2.6-5.44C234.71,41.78,234.54,45.42,234.51,47.19Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M253.26,50.51c0-1.49-1.15-2-2.64-2H249V45.83h1.84a3.33,3.33,0,0,0,1.45-.24,2,2,0,0,0,1.08-1.77,1.9,1.9,0,0,0-.94-1.83,2.84,2.84,0,0,0-1.35-.28,3.4,3.4,0,0,0-1.91.62l0,1.74h-2.74l-.17-2.57a5.21,5.21,0,0,1,2.11-1.59,7.84,7.84,0,0,1,3.47-.7,6.36,6.36,0,0,1,3.39.91,4,4,0,0,1,1.84,3.43,3.58,3.58,0,0,1-1,2.39,3.37,3.37,0,0,1-1.52.9v.07a3.6,3.6,0,0,1,2.08,1,3.31,3.31,0,0,1,1,2.42,4.35,4.35,0,0,1-1.94,3.68A7.83,7.83,0,0,1,251,55.26a8.61,8.61,0,0,1-2.8-.55,5.1,5.1,0,0,1-2.74-2.15l1.7-1.7a4.92,4.92,0,0,0,3.67,1.73A2.16,2.16,0,0,0,253.26,50.51Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M264.56,55h-3.64V41.78h-2.26V39.49h6.62v9.32a8.11,8.11,0,0,1,.9-1.73l4.75-7.59h3.68V50.82c0,.63-.11,1.56.83,1.56a3.66,3.66,0,0,0,1.25-.45l.86,1.81a5.62,5.62,0,0,1-3.88,1.42,3.79,3.79,0,0,1-2.74-1,5.06,5.06,0,0,1-.72-3.36V45.56a6.52,6.52,0,0,1-.87,1.69Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M279.63,55V41.78h-2.25V39.49h9.53c1.53,0,3.12.17,4.19,1.35a3.59,3.59,0,0,1,.94,2.71,3.46,3.46,0,0,1-2.53,3.29v.07a3.65,3.65,0,0,1,2.11,1,3.41,3.41,0,0,1,1,2.42,4.28,4.28,0,0,1-1.9,3.61c-1.39.93-3.57,1-5.2,1ZM284,42.09v3.74h1.59a3.38,3.38,0,0,0,1.46-.24,1.77,1.77,0,0,0,1-1.7,1.67,1.67,0,0,0-.59-1.42,3.06,3.06,0,0,0-1.73-.38Zm1.73,10.22a2.93,2.93,0,0,0,1.8-.45,1.81,1.81,0,0,0,.7-1.45,1.76,1.76,0,0,0-.7-1.53,3.39,3.39,0,0,0-1.94-.41H284v3.84Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M309.09,39.49V50.82c0,.63-.1,1.56.83,1.56a4,4,0,0,0,1.29-.45l.83,1.81a5.52,5.52,0,0,1-3.88,1.42,3.71,3.71,0,0,1-2.71-1,4.91,4.91,0,0,1-.76-3.36V48.64h-4.37V55H296V41.78H293.7V39.49h6.62v6.45h4.37V39.49Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M315.88,40.67a7.41,7.41,0,0,1,4.48-1.42,6.76,6.76,0,0,1,5.06,2.08,8.56,8.56,0,0,1,2.08,6.1,8.72,8.72,0,0,1-1.91,5.4,6.88,6.88,0,0,1-5.44,2.36A6.79,6.79,0,0,1,314.91,53a8.19,8.19,0,0,1-1.9-6A8.16,8.16,0,0,1,315.88,40.67Zm1.6,6.52c0,1.73.31,5.4,2.7,5.44,2.57,0,2.81-3.61,2.84-5.38s-.34-5.33-2.66-5.4C317.76,41.81,317.48,45.42,317.48,47.19Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M339.59,52.11a5.87,5.87,0,0,0,1.53-1.29l1.59,1.7a6.51,6.51,0,0,1-5.51,2.71,7,7,0,0,1-5.3-2.15,8.71,8.71,0,0,1-2-6.07,8.34,8.34,0,0,1,2.18-5.82,6.58,6.58,0,0,1,4.75-2,6,6,0,0,1,4.71,2,8,8,0,0,1,1.67,5,22,22,0,0,1-.11,2.25h-8.66a5.65,5.65,0,0,0,1,3,2.74,2.74,0,0,0,2.25,1.15A3.55,3.55,0,0,0,339.59,52.11ZM339,45.9a7.42,7.42,0,0,0-.62-3.25,1.63,1.63,0,0,0-1.46-.87,2.13,2.13,0,0,0-2,1.42,6.45,6.45,0,0,0-.52,2.7Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M152.56,68.12a8.16,8.16,0,0,1,4.92-1.56A7.37,7.37,0,0,1,163,68.85a9.35,9.35,0,0,1,2.29,6.71,9.61,9.61,0,0,1-2.09,6,7.61,7.61,0,0,1-6,2.59,7.46,7.46,0,0,1-5.76-2.37,9,9,0,0,1-2.1-6.63A9,9,0,0,1,152.56,68.12Zm1.75,7.17c0,1.91.35,5.95,3,6,2.82,0,3.09-4,3.12-5.91s-.38-5.88-2.93-6C154.62,69.38,154.31,73.35,154.31,75.29Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M179.36,60.53l1.11-1.41,2.82,1.18a5.83,5.83,0,0,1-.76,2.71,3.88,3.88,0,0,1-1.87,1.56,26.48,26.48,0,0,1-4.08,1.26c-1.34.39-3.13.88-4.08,1.91a5.68,5.68,0,0,0-1.34,3.47,7.05,7.05,0,0,1,2-2.17,7.26,7.26,0,0,1,4.23-1.3,6.5,6.5,0,0,1,4.73,2,8.23,8.23,0,0,1,1.87,5.84,9.38,9.38,0,0,1-2.1,6,7.61,7.61,0,0,1-6,2.59,7.19,7.19,0,0,1-6.29-3.36,12,12,0,0,1-1.6-5.95,16.62,16.62,0,0,1,1.18-7.36,9.07,9.07,0,0,1,4.16-4.38C175.32,62,177.84,61.18,179.36,60.53Zm-3.47,20.75c2.86,0,3.09-4,3.13-5.91a6.74,6.74,0,0,0-.88-3.82,2.54,2.54,0,0,0-2-1,2.78,2.78,0,0,0-2.44,1.8,7.52,7.52,0,0,0-.69,3.2C173,77.46,173.26,81.24,175.89,81.28Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M187.79,69.34h-2.48V66.82h6.86c2.94,0,6.67.12,8.58,2.79a8.59,8.59,0,0,1,1.49,5.3c.07,2.94-.76,5.53-2.79,7.17s-4.46,1.79-6.86,1.75v8.24h-4.8Zm4.8,11.55c1.79,0,3.17-.38,4-1.94a8.11,8.11,0,0,0,.8-3.93c0-1.94-.38-3.35-1.33-4.27a4.62,4.62,0,0,0-3.51-1Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M214.44,73.88v-2.1a2.57,2.57,0,0,0-.61-1.87,2.45,2.45,0,0,0-1.76-.61,3.72,3.72,0,0,0-1.6.35v2.7H205.9a4.88,4.88,0,0,1,1.25-4A6.42,6.42,0,0,1,209.4,67a11.2,11.2,0,0,1,3.55-.42,7.84,7.84,0,0,1,4.84,1.52,4.83,4.83,0,0,1,1.49,3.7v7.55c0,.69-.11,1.72.92,1.72a3.21,3.21,0,0,0,1.29-.5l.92,2a5.81,5.81,0,0,1-4.16,1.56,3.75,3.75,0,0,1-3.09-1.22,8.21,8.21,0,0,1-4.65,1.26A6.89,6.89,0,0,1,206.66,83a4.79,4.79,0,0,1-1.87-3.73,4.63,4.63,0,0,1,2.82-4.69,11.57,11.57,0,0,1,4.54-.69Zm-.88,2.63c-1.11,0-2.67,0-3.55.72a2.3,2.3,0,0,0-.68,1.72,2,2,0,0,0,.8,1.75,2.89,2.89,0,0,0,1.75.46,6,6,0,0,0,2.56-.87c0-1.23,0-2.52,0-3.78Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M230.72,79c0-1.64-1.26-2.25-2.9-2.25h-1.76V73.8h2a3.88,3.88,0,0,0,1.6-.26,2.17,2.17,0,0,0,1.18-2,2.08,2.08,0,0,0-1-2,3.16,3.16,0,0,0-1.49-.31,3.74,3.74,0,0,0-2.1.69l0,1.91h-3L223,69a5.92,5.92,0,0,1,2.33-1.76,8.66,8.66,0,0,1,3.81-.76,7,7,0,0,1,3.74,1,4.35,4.35,0,0,1,2,3.78,3.93,3.93,0,0,1-1.11,2.63,3.7,3.7,0,0,1-1.67,1V75a4,4,0,0,1,2.28,1.14,3.64,3.64,0,0,1,1.11,2.67,4.77,4.77,0,0,1-2.14,4,8.57,8.57,0,0,1-5.14,1.33,9.34,9.34,0,0,1-3.09-.61,5.54,5.54,0,0,1-3-2.36L224,79.33a5.45,5.45,0,0,0,4.05,1.91A2.37,2.37,0,0,0,230.72,79Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M241.08,68.12A8.18,8.18,0,0,1,246,66.56a7.39,7.39,0,0,1,5.57,2.29,9.4,9.4,0,0,1,2.29,6.71,9.61,9.61,0,0,1-2.1,6,7.61,7.61,0,0,1-6,2.59A7.45,7.45,0,0,1,240,81.73a8.94,8.94,0,0,1-2.1-6.63A9,9,0,0,1,241.08,68.12Zm1.76,7.17c0,1.91.34,5.95,3,6,2.82,0,3.09-4,3.13-5.91s-.38-5.88-2.94-6C243.14,69.38,242.84,73.35,242.84,75.29Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M257.71,83.83V69.34h-2.48V66.82h10.49c1.67,0,3.43.2,4.61,1.49a3.93,3.93,0,0,1,1,3,3.84,3.84,0,0,1-2.78,3.62V75a4.06,4.06,0,0,1,2.32,1.14A3.77,3.77,0,0,1,272,78.8a4.68,4.68,0,0,1-2.1,4c-1.52,1-3.93,1.07-5.72,1.07Zm4.8-14.15V73.8h1.76a3.88,3.88,0,0,0,1.6-.26A1.94,1.94,0,0,0,267,71.67a1.84,1.84,0,0,0-.65-1.57,3.37,3.37,0,0,0-1.9-.42Zm1.91,11.25a3.27,3.27,0,0,0,2-.49,2,2,0,0,0,.76-1.6,1.92,1.92,0,0,0-.76-1.68,3.7,3.7,0,0,0-2.13-.46h-1.76v4.23Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M284,73.88v-2.1a2.58,2.58,0,0,0-.62-1.87,2.44,2.44,0,0,0-1.75-.61,3.72,3.72,0,0,0-1.6.35v2.7h-4.58a4.89,4.89,0,0,1,1.26-4A6.33,6.33,0,0,1,279,67a11.2,11.2,0,0,1,3.55-.42,7.84,7.84,0,0,1,4.84,1.52,4.83,4.83,0,0,1,1.49,3.7v7.55c0,.69-.12,1.72.91,1.72a3.25,3.25,0,0,0,1.3-.5l.91,2a5.79,5.79,0,0,1-4.15,1.56,3.73,3.73,0,0,1-3.09-1.22,8.21,8.21,0,0,1-4.65,1.26A6.87,6.87,0,0,1,276.24,83a4.73,4.73,0,0,1-1.87-3.73,4.62,4.62,0,0,1,2.82-4.69,11.54,11.54,0,0,1,4.54-.69Zm-.88,2.63c-1.11,0-2.67,0-3.55.72a2.3,2.3,0,0,0-.68,1.72,2,2,0,0,0,.8,1.75,2.87,2.87,0,0,0,1.75.46,6,6,0,0,0,2.56-.87c0-1.23,0-2.52,0-3.78Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M308.45,66.82V79.29c0,.69-.11,1.72.92,1.72a4.54,4.54,0,0,0,1.41-.5l.92,2a6.11,6.11,0,0,1-4.28,1.56,4,4,0,0,1-3-1.11c-.88-1-.84-2.4-.84-3.69V76.89h-4.8v6.94H294V69.34h-2.48V66.82h7.29v7.1h4.8v-7.1Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M318,83.83h-4V69.34H311.5V66.82h7.28V77.08a8.78,8.78,0,0,1,1-1.9L325,66.82h4V79.29c0,.69-.11,1.72.92,1.72a4,4,0,0,0,1.37-.5l.95,2A6.21,6.21,0,0,1,328,84.06,4.11,4.11,0,0,1,325,83c-.84-1-.8-2.4-.8-3.69V73.5a7,7,0,0,1-1,1.87Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M344,80.7a6.25,6.25,0,0,0,1.68-1.41l1.76,1.87a7.17,7.17,0,0,1-6.07,3,7.69,7.69,0,0,1-5.83-2.37,9.58,9.58,0,0,1-2.21-6.67,9.14,9.14,0,0,1,2.4-6.41A7.22,7.22,0,0,1,341,66.52a6.59,6.59,0,0,1,5.19,2.21A8.8,8.8,0,0,1,348,74.18a23.41,23.41,0,0,1-.12,2.48h-9.53a6.33,6.33,0,0,0,1.07,3.28,3,3,0,0,0,2.48,1.26A3.77,3.77,0,0,0,344,80.7Zm-.64-6.82a8.2,8.2,0,0,0-.69-3.58,1.81,1.81,0,0,0-1.6-1,2.32,2.32,0,0,0-2.21,1.57,7.08,7.08,0,0,0-.58,3Z" style="fill: rgb(19, 24, 25);"></path>
    </g>
    <g class="en" style="display: none;">
        <path d="M156.07,29.9V50.41c0,.69-.12,1.72.91,1.72a4.37,4.37,0,0,0,1.41-.5l.92,2a7.1,7.1,0,0,1-4.69,1.57,4.11,4.11,0,0,1-3-1.11,4.7,4.7,0,0,1-.83-3.2V32.68h-2.67V29.9Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M166.44,39.32a9,9,0,0,1,1.9-1.11,7.85,7.85,0,0,1,2.71-.5,5.34,5.34,0,0,1,3.47,1,5.36,5.36,0,0,1,1.6,4.35v7.32c0,.69-.07,1.72,1,1.72a4.75,4.75,0,0,0,1.37-.5l1,2a6.17,6.17,0,0,1-4.27,1.57,4.1,4.1,0,0,1-3-1.11c-.88-1-.84-2.4-.84-3.7v-7a3.12,3.12,0,0,0-.46-1.87,1.72,1.72,0,0,0-1.45-.61,4.79,4.79,0,0,0-2.78,1.33V55h-4.8V40.46h-2.48V37.94h7Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M192.78,50.41l1.72,1.83a6.82,6.82,0,0,1-5.91,3,7.46,7.46,0,0,1-6.45-3,9.87,9.87,0,0,1-1.67-6,8.79,8.79,0,0,1,3.28-7.13,7.63,7.63,0,0,1,4.53-1.41,6.49,6.49,0,0,1,5.11,1.94,5.28,5.28,0,0,1,1,4h-4.65V40.69a2.69,2.69,0,0,0-1.26-.27,2.59,2.59,0,0,0-2.17,1.22,9.41,9.41,0,0,0-1,4.77c.08,2.74,1,5.83,3.74,5.91A4.49,4.49,0,0,0,192.78,50.41Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M202.54,50.45c0,.69-.07,1.72,1,1.72a3.73,3.73,0,0,0,1.37-.54l.95,2a6.1,6.1,0,0,1-4.27,1.57,3.88,3.88,0,0,1-3-1.07c-.88-1-.84-2.4-.84-3.7v-18h-2.48V29.9h7.28Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M215.69,54.64a7,7,0,0,1-2.7.54c-3.36,0-5.07-2-5.07-5.15V37.9h4.84V49.73c0,1.37.49,2.24,1.91,2.24a4.49,4.49,0,0,0,2.66-1.22V37.94h4.81V50.45c0,.69-.08,1.72,1,1.72a3.87,3.87,0,0,0,1.37-.54l.92,2a5.94,5.94,0,0,1-4.23,1.57,3.86,3.86,0,0,1-3-1.07,1.92,1.92,0,0,1-.42-.69,6.81,6.81,0,0,1-1.1.77A7.05,7.05,0,0,1,215.69,54.64Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M237.92,40a6,6,0,0,1,.65,3.36h-4.08v-2.6a3,3,0,0,0-1.64-.42c-1.26,0-2.29.58-2.33,1.87s.84,1.72,1.76,2.1c1.33.53,2.78,1,4.08,1.53a4.19,4.19,0,0,1,3,4.23c-.07,3.85-3.85,5.22-7.32,5.11a9.3,9.3,0,0,1-3.28-.73A7.16,7.16,0,0,1,226,52.58l1.6-2.09a7.65,7.65,0,0,0,4.2,1.75,3.35,3.35,0,0,0,2.17-.46,1.48,1.48,0,0,0,.57-1.06c0-.92-.57-1.3-1.3-1.64a44.54,44.54,0,0,1-4.69-2.22,4.78,4.78,0,0,1-.57-7.74,7,7,0,0,1,4.65-1.41C234.53,37.75,236.85,38.29,237.92,40Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M247.19,35.35h-5V30.7h5Zm-4.92,2.59V50.41c0,1.3,0,2.71.84,3.7a3.88,3.88,0,0,0,3,1.07,6.1,6.1,0,0,0,4.27-1.57l-.95-2a3.47,3.47,0,0,1-1.38.54c-1,0-.91-1-.91-1.72V37.94Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M265.94,37.94a15.34,15.34,0,0,1-.53,4.92l-2.75,10.22c-.34,1.22-.72,1.87-2.17,1.87H257.4a2.54,2.54,0,0,1-2.17-.92,6,6,0,0,1-.8-2.21l-2.67-11.36H249.4V37.94h6.74l3.06,14h.38l.8-3.35a35.57,35.57,0,0,0,1-7.51,18.3,18.3,0,0,0-.16-3.17Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M278.68,51.82a6.4,6.4,0,0,0,1.68-1.41l1.75,1.87a7.15,7.15,0,0,1-6.06,3,7.71,7.71,0,0,1-5.84-2.36A9.63,9.63,0,0,1,268,46.22a9.23,9.23,0,0,1,2.4-6.41,7.25,7.25,0,0,1,5.23-2.17,6.57,6.57,0,0,1,5.18,2.21,8.74,8.74,0,0,1,1.83,5.45,23.57,23.57,0,0,1-.11,2.48H273a6.24,6.24,0,0,0,1.06,3.28,3,3,0,0,0,2.48,1.26A3.8,3.8,0,0,0,278.68,51.82ZM278,45a8.27,8.27,0,0,0-.69-3.59,1.78,1.78,0,0,0-1.6-.95A2.34,2.34,0,0,0,273.53,42a7,7,0,0,0-.57,3Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M148.14,63.59V61.06H163.7v3h-8.36v7.1H162v3h-6.62v6.83h8.32v2.94h-13.1V63.59Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M174.3,83.83c-2.22,0-4.71-.17-6.51-1.66s-2.36-3.78-2.32-6.45a7.09,7.09,0,0,1,2.32-5.54c1.8-1.56,4.16-1.81,6.44-1.81v-5H172V61.06h6.62V83.83ZM174.23,71a4.13,4.13,0,0,0-3.15.9c-.87.83-1.21,2.12-1.21,3.89a7.05,7.05,0,0,0,.66,3.57,3.75,3.75,0,0,0,3.7,1.76Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M189.69,83.55a6.35,6.35,0,0,1-2.46.49c-3,0-4.61-1.84-4.61-4.68v-11H187V79.08c0,1.25.45,2,1.74,2A4.08,4.08,0,0,0,191.18,80V68.37h4.37V79.74c0,.63-.07,1.56.87,1.56a3.66,3.66,0,0,0,1.24-.48l.83,1.8A5.41,5.41,0,0,1,194.65,84a3.52,3.52,0,0,1-2.71-1,1.76,1.76,0,0,1-.38-.62,6.84,6.84,0,0,1-1,.69A6.3,6.3,0,0,1,189.69,83.55Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M210.66,79.71l1.56,1.66a6.19,6.19,0,0,1-5.37,2.7,6.77,6.77,0,0,1-5.86-2.7,8.92,8.92,0,0,1-1.52-5.48,8,8,0,0,1,3-6.48,7.08,7.08,0,0,1,4.12-1.28,5.9,5.9,0,0,1,4.64,1.77,4.79,4.79,0,0,1,.94,3.64h-4.23V70.87a2.45,2.45,0,0,0-1.14-.24,2.34,2.34,0,0,0-2,1.11,8.55,8.55,0,0,0-.86,4.33c.07,2.49.9,5.3,3.39,5.37A4.13,4.13,0,0,0,210.66,79.71Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M222.58,74.79V72.88a2.34,2.34,0,0,0-.55-1.7,2.21,2.21,0,0,0-1.6-.55,3.39,3.39,0,0,0-1.45.31V73.4h-4.16A4.45,4.45,0,0,1,216,69.73a5.85,5.85,0,0,1,2-1.22,10.58,10.58,0,0,1,3.22-.38,7.08,7.08,0,0,1,4.4,1.39A4.4,4.4,0,0,1,227,72.88v6.86c0,.63-.11,1.56.83,1.56a3,3,0,0,0,1.17-.45l.84,1.77A5.28,5.28,0,0,1,226.05,84a3.38,3.38,0,0,1-2.81-1.11A7.44,7.44,0,0,1,219,84.07a6.28,6.28,0,0,1-3.5-1,4.34,4.34,0,0,1-1.69-3.4,4.21,4.21,0,0,1,2.56-4.26,10.57,10.57,0,0,1,4.12-.62Zm-.79,2.39c-1,0-2.43,0-3.23.66a2.07,2.07,0,0,0-.62,1.56,1.86,1.86,0,0,0,.73,1.59,2.68,2.68,0,0,0,1.59.41,5.21,5.21,0,0,0,2.32-.79c0-1.11,0-2.29,0-3.43Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M239.57,71.08H236v8.66c0,.63-.11,1.56.83,1.56a3.53,3.53,0,0,0,1.28-.48l.83,1.8A5.49,5.49,0,0,1,235.1,84a3.51,3.51,0,0,1-2.71-1,4.91,4.91,0,0,1-.76-3.36V71.08H229.2V68.37h2.43V66.23l1.73-2H236v4.12h3.57Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M246.81,66h-4.54V61.79h4.54Zm-4.47,2.35V79.71c0,1.18,0,2.46.76,3.36a3.55,3.55,0,0,0,2.71,1,5.6,5.6,0,0,0,3.88-1.42l-.87-1.8a3.24,3.24,0,0,1-1.25.48c-.93,0-.83-.93-.83-1.56V68.37Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M253.53,69.55A7.28,7.28,0,0,1,258,68.13a6.62,6.62,0,0,1,5.06,2.08,8.47,8.47,0,0,1,2.08,6.1,8.75,8.75,0,0,1-1.9,5.41,7.47,7.47,0,0,1-10.68.2,8.36,8.36,0,0,1-1.9-6A8.16,8.16,0,0,1,253.53,69.55Zm1.6,6.52c0,1.73.31,5.4,2.7,5.44,2.57,0,2.81-3.6,2.84-5.37s-.34-5.34-2.67-5.41C255.4,70.7,255.13,74.3,255.13,76.07Z" style="fill: rgb(19, 24, 25);"></path>
        <path d="M272.87,69.62a8.3,8.3,0,0,1,1.74-1,7.07,7.07,0,0,1,2.46-.45,4.19,4.19,0,0,1,4.61,4.88v6.66c0,.62-.07,1.56.86,1.56a4.46,4.46,0,0,0,1.25-.45l.87,1.8A5.65,5.65,0,0,1,280.78,84,3.72,3.72,0,0,1,278,83c-.8-.9-.76-2.18-.76-3.36V73.3a2.89,2.89,0,0,0-.42-1.7,1.57,1.57,0,0,0-1.32-.56A4.32,4.32,0,0,0,273,72.26V83.83h-4.36V70.66h-2.26V68.37h6.35Z" style="fill: rgb(19, 24, 25);"></path>
    </g>
</svg>
</template>

<script>
export default {

}
</script>

<style>

</style>