import Vue from 'vue'
import Keycloak from 'keycloak-js'

const initOptions = {
    url: 'https://auth.miccedu.ru/auth/',
    realm: 'givc',
    clientId: 'incedu-crm'
}

const keycloak = Keycloak(initOptions)

const KeycloakPlugin = {
    install: Vue => {
        Vue.$keycloak = keycloak
    }
}

Vue.use(KeycloakPlugin)

export default KeycloakPlugin