import axios from "axios";

export const axiosInst = axios.create({
    baseURL: ( location.href.indexOf("localhost") != -1 ? "http://localhost:3001/" : "https://црм-система.инклюзивноеобразование.рф" ),
    headers: {
        "Content-type": "application/json"
    }
});

axiosInst.interceptors.response.use(function (response) {
    return response;
 }, async function (error) {
    console.log(error)
    if ( [401, 403].includes(error.response.status) ) {
    //    const { logout : logoutAuth, authenticating } = useAuth();
    //    if ( KeycloakInstance?.onReady ) {
    //       await KeycloakInstance.logout()
    //    }
    //    logoutAuth()
       location.href = '/forbidden'
    } else {
       return Promise.reject(error);
    }
 });

 export default axiosInst;