import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
import VueRouter from 'vue-router'
import router from './plugins/router'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import VueApexCharts from 'vue-apexcharts'

import axios from './plugins/http-common'
import VueAxios from 'vue-axios'

import '@/plugins/keycloak'
import { updateToken } from '@/plugins/keycloak-util'

import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

// https://themeselection.com/demo/materio-vuetify-vuejs-admin-template/demo-3/dashboards/crm
// https://themeselection.com/demo/materio-vuetify-vuejs-admin-template/demo-5/dashboards/eCommerce
// https://themeselection.com/demo/materio-vuetify-vuejs-admin-template/demo-1/dashboards/crm
// https://medium.com/devops-dudes/secure-front-end-react-js-and-back-end-node-js-express-rest-api-with-keycloak-daf159f0a94e

const moment = require('moment')
require('moment/locale/ru')
Vue.use(require('vue-moment'), {
    moment
})

import '@/assets/confirm.css'
import confirm from '@/plugins/confirm'
Vue.use(confirm)

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.use(VueToast);
Vue.use(VueRouter)
Vue.use(VueAxios, axios)

Vue.config.productionTip = false

Vue.$keycloak.init({ onLoad: 'login-required' }).then((auth) => {
  if (!auth) {
    window.location.reload();
  } else {
    new Vue({
      vuetify,
      router,
      render: h => h(App),
    }).$mount('#app')

    window.onfocus = () => {
      updateToken()
    }
  }
})