<template>
	<v-app id="inspire" style="background-color:#F4F5FA">
		<!-- <v-navigation-drawer v-model="drawer" app>
		</v-navigation-drawer> -->

		<v-app-bar app elevation="0" height="64" class="app-special-drawler" color="#fff">
		<div class="drawler-absolute">
			<!-- <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon> -->
		</div>
		<div class="container-content">
			<logo></logo>
			<v-toolbar-title>
				<span class="pl-2">.CRM</span>
			</v-toolbar-title>
			<v-spacer></v-spacer>

			<!-- <v-responsive max-width="260">
				<v-text-field
				dense
				flat
				hide-details
				rounded
				solo-inverted
				></v-text-field>
			</v-responsive> -->

			<v-spacer></v-spacer>

			<v-btn @click="logout()" elevation="0" color="secondary">Выйти</v-btn>
		</div>
		</v-app-bar>
		<v-main>

		<v-toolbar elevation="2" height="60">
			<div class="container-content">
				<v-btn elevation="0" color="indigo" dark :to="{name:'StartPage'}">
					<v-icon>mdi-apps</v-icon> Главная
				</v-btn>
				<v-btn elevation="0" class="ml-2" :to="{name:'Users'}">
					<v-icon class="pr-1">mdi-account-multiple-outline</v-icon> Пользователи
				</v-btn>
				<v-btn elevation="0" class="ml-2" :to="{name:'Organizations'}">
					<v-icon class="pr-1">mdi-school</v-icon> Организации
				</v-btn>
				<v-btn elevation="0" class="ml-2" :to="{name:'Rumcs'}">
					<v-icon class="pr-1">mdi-human-wheelchair</v-icon> РУМЦ
				</v-btn>
				<v-btn elevation="0" class="ml-2" :to="{name:'Events'}">
					<v-icon class="pr-1">mdi-calendar-check-outline</v-icon> События
				</v-btn>
				<v-btn elevation="0" class="ml-2" :to="{name:'News'}">
					<v-icon class="pr-1">mdi-newspaper-variant-multiple-outline</v-icon> Новости
				</v-btn>
			</div>
		</v-toolbar>

		<!--  -->
		<div class="container-content pt-3 pb-3">
				<router-view></router-view>
		</div>
		</v-main>
	</v-app>
</template>

<script>
import { updateToken, logout } from '@/plugins/keycloak-util'
const AUTHORIZATION_HEADER = 'Authorization'
import logo from './components/logo.vue'
export default {
	name: 'App',
	data: () => ({ drawer: false }),
	components: {
		logo
	},
	watch: {
		$route() {
			updateToken()
		}
	},
	methods: {
		logout() {
			console.log(logout())
		}
	},
	created: function () {
		this.axios.interceptors.request.use(async config => {
			const token = await updateToken()
			config.headers.common[AUTHORIZATION_HEADER] = `Bearer ${token}`
			return config
		})
	}
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;600&display=swap');
#app {font-family: 'Inter', sans-serif !important;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;color: #7E7786 !important;}

.app-special-drawler { position: relative; border-bottom:1px solid #E6E6E9 !important; }
.drawler-absolute { position: absolute; left:10px; top:0; height:inherit; display: flex; align-items: center; }
.container-content { width:1440px; margin:0 auto; display: flex; align-items: center; }
</style>
